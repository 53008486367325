<template style="width: 100%;height: 100%;" >
  <div v-loading.fullscreen.lock="loading"
       class="login"
       element-loading-spinner="fa fa-spinner fa-pulse fa-3x fa-fw">
    <el-form :rules="rules"
             :model="form"
             class="logContainer"
             ref="form"
             @keydown.enter.native="regSubmit">
      <h2 class="logtitle"><i class="fa fa-user"
             style="color: #505458" />&nbsp;人 事 管 理</h2>
             
      <el-form-item prop="username">
        <el-input type="text"
                  v-model="form.username"
                  placeholder="请输入用户名"
                  auto-complete="off">
          <i slot="prefix"
             class="el-icon-user"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password"
                  v-model="form.password"
                  placeholder="请输入密码"
                  auto-complete="off">
          <i slot="prefix"
             class="el-icon-lock"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="phone">
        <el-input type="text"
                  v-model="form.phone"
                  placeholder="请输入手机号"
                  auto-complete="off">
          <i slot="prefix"
             class="el-icon-phone"></i>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code"
                  auto-complete="off"
                  placeholder="验证码"
                  style="width: 63%"
                  @keyup.enter.native="regSubmit">
          <i slot="prefix"
             class="el-icon-view"></i>
        </el-input>
    
        <div class="login-code">
        <el-button type="primary"  :disabled='isforbidden' @click="verificationcode">获取验证码</el-button>
    </div>

      </el-form-item>
      <!-- <el-form-item prop="email">
        <el-input type="text"
                  v-model="form.email"
                  placeholder="请输入邮箱"
                  auto-complete="off">
          <i slot="prefix"
             class="el-icon-user"></i>
        </el-input>
      </el-form-item> -->
     
      <el-button type="primary"
                 style="width: 100%"
                 @click="regSubmit">注册</el-button>
    
    <div class="login_" style="font-size: 13px;">已有账号，
        <router-link to="/">登录</router-link>
        <!-- <span style="margin-left: 8%; font-size:13px">注册即代表您已阅读并同意《使用协议》</span> -->
    </div>      
               
  
  </el-form>

 


  </div>
</template>

<script>
// import {postKeyValueRequest} from '../utils/api';
// 在main.js里以插件形似全局导入
export default {
  data () {
    return {
      codeUrl: '',
      cookiePass: '',
      loading: false,
      redirect: undefined,
      // form: {
      //   username: 'user1',
      //   password: '123',
      //   code: '',
      //   email:''
      // },
      
      isforbidden:false,
      form:{
        username:'user1',
        password: '123',
        code: '',
        verify:'',
        psw:'',
        pswok:'',
        email:'',
        phone:'15508322557'
      },
      checked: true,
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 25, message: '长度在 1 到 25 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: blur() },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        code: [{ required: true, trigger: 'change', message: '验证码不能为空' }]
      }
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created () {
    // this.getCode()

  },
  methods: {
    verificationcode(e){
          if(this.form.phone==''){
            this.$message.error('请输入手机号');
          }else{
            this.getRequest("/auth/sms",this.form).then(resp => {
              if(resp){
              console.log("发送结果"+resp)
              
        
        }
       
      });
           
            this.isforbidden=true;
            let k = 60;
            let time = setInterval(()=>{
              if(k==0){
                e.target.innerHTML="获取验证码";
                this.isforbidden = false;
                clearInterval(time);
              }else{
                e.target.innerHTML="还剩"+k+"秒";
                k--;
              }
            },1000)
          }
        },
    

    regSubmit () {
      //提交前空值校验
      this.$refs.form.validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.loading = true;
          this.postKeyValueRequest('/reg', this.form
          ).then(data => {
            this.loading = false;
            if (data) {
              console.log(data);
              

              //方法将 JavaScript 的json对象转换为字符串。
              //将得到数存储在SessionStorage里
              // window.sessionStorage.setItem("user", JSON.stringify(data.obj))
              // //获取路由对象
              // this.$router.replace('/home')
            }
          })
        } else {
          this.$notify.info({
            title: '系 统 讯 息',
            message: '输入框信息不完整哦!',
            showClose: false,
            offset: 100,
            duration: 5000,
            customClass: 'fontclass'
          });
        }
      });

    }
  }
}
</script>

<style >
.fontclass {
  font-size: 35px;
  font-family: 站酷庆科黄油体;
}

.login {
  background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: fixed;
   background-image: url(../assets/images/timg.jpg);
}
.logContainer {
  /* //圆角边框*/
  border-radius: 15px;
  /*背景裁剪在背景边框内部*/
  background-clip: padding-box;
  /*//外边距*/
  margin: 250px auto;
  /*//宽度*/
  width: 350px;
  /*//内边距*/
  padding: 35px 35px 15px 35px;
  /*//背景色*/
  background: transparent;
  background-image: radial-gradient(#ffffff, transparent);
  /*// 边框样式*/
  border: 1px solid #eaeaea;
  /*// 边框阴影*/
  box-shadow: 0 0 25px #cac6c6;
}
.logtitle {
  margin: 0px auto 20px auto;
  text-align: center;
  color: #505458;
  font-family: 站酷庆科黄油体;
}
.loginRen {
  text-align: center;
  margin: 0px 0px 35px 0px;
}
.fontclass {
  font-size: 35px;
  font-family: 站酷庆科黄油体;
}
.login-code {
  width: 33%;
  display: inline-block;
  height: 38px;
  float: right;
}
.login-code img {
  cursor: pointer;
  vertical-align: middle;
}
</style>
